import React from "react"
import OneTrustContent from "../components/OneTrustContent";

export default ()=>(
  <OneTrustContent 
    id="otnotice-f0829d55-f04e-4d84-8907-59b212f9f17f"
    url="https://privacyportal-cdn.onetrust.com/722df920-f42e-4d91-b843-6306f9a6a5a0/privacy-notices/f0829d55-f04e-4d84-8907-59b212f9f17f.json"
    title="Terms of Use"
  />
);
