import React, { useEffect } from "react"
import Layout from "../components/Layout";
import { HeaderH1 } from "../helpers/styledComponents";
import styled from "styled-components";

interface IHeaderContentProps{
  title: string;
}
const HeaderContent = ({ title }:IHeaderContentProps)=>(
  <HeaderH1>{title}</HeaderH1>
);


const TextContentWrap = styled.div`
  .textCenter{
    text-align: center;
  }

  padding-bottom: 3rem;
`;


interface IProps {
  id: string;
  url: string;
  title: string;
}

export default ({
  id,
  url,
  title
}:IProps) => {
  let interval:any;
  useEffect(() => {
    //interval is needed to check when OneTrust api become available via Window
    interval = setInterval(()=>{
      if(window && window.OneTrust){
        window.OneTrust.NoticeApi.Initialized.then(function () {
          window.OneTrust.NoticeApi.LoadNotices([ url ]);
        });
        clearInterval(interval);
      }
    }, 200);
  }, [url])

  return (
    <Layout mode="light" headerContent={<HeaderContent title={title} />}>
      <TextContentWrap>
      <div id={id} className="otnotice">
        <div className="textCenter">
          ...loading
        </div>
      </div>
      </TextContentWrap>
    </Layout>
  )
}